import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../../auth/auth";
import { getLocationLogger } from "../../../../api/apiCall";
import { format } from "date-fns";
import "./LocationLogger.css";

const LocationLogger: React.FC = () => {
    const { state } = useAuth();
    const [workers, setWorkers] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [pagination, setPagination] = useState<number>(1);

    const fetchWorkers = async (page: number, search?: string) => {
        try {
            const response = await getLocationLogger(state.user?.token, page, search);
            if (response && Array.isArray(response)) {
                setWorkers(response);
            } else {
                setWorkers([]);
                setPagination(1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = (page: number) => {
        if (page > 0) {
            setPagination(page);
        }
    };

    useEffect(() => {
        fetchWorkers(pagination, search);
    }, [pagination, search]);

    return (
        <div className="location-container">
            <header className="header-logger">
                <h1>Location Logger
                    <h4 className="uk-time">UK TIME ZONE</h4>
                </h1>
                <TextField
                    label="Search..."
                    type="text"
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "20%", margin: 10 }}
                />
            </header>

            <table className="location-logger-table">
                <thead>
                    <tr>
                        <th className="left-align">Full Name</th>
                        <th>PTS</th>
                        <th>Suffix</th>
                        <th>Phone Number</th>
                        <th>Geofence Name</th>
                        <th>Date Time</th>
                        <th>Enter/Exit</th>
                    </tr>
                </thead>
                <tbody>
                    {workers.length > 0 ? (
                        workers.map((worker: any) => (
                            <tr key={worker.id}>
                                <td style={{textAlign: "left"}}>{worker.user.fullName}</td>
                                <td>{worker.user.pts}</td>
                                <td>PACE</td>
                                <td>{worker.user.phoneNumber}</td>
                                <td>{worker.geofenceName || "Newquay"}</td>
                                <td>
                                    {format(new Date(worker.createdDate), "do MMM, HH:mm")}
                                </td>
                                <td
                                    className="table-data"
                                    style={{
                                        color: worker.locationLogType === 2 ? "#fd7e14" : "#28a745",
                                        fontWeight: 500,
                                    }}
                                >
                                    {worker.locationLogType === 2 ? "EXIT" : "ENTER"}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} className="table-data center-align">
                                No workers found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="pagination-div">
                <Button onClick={() => handlePageChange(pagination - 1)} disabled={pagination === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <h3 style={{ margin: "0 10px" }}>{pagination}</h3>
                <Button onClick={() => handlePageChange(pagination + 1)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    );
};

export default LocationLogger;