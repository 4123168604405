import React, { useEffect, useState } from "react"
import { getWorkersFromJob } from "../../../../../api/apiCall";
import { useAuth } from "../../../../../auth/auth";
import './WorkersTable.css'

const WorkersTable = ({id}: any) => {
    const {state} = useAuth();
    const [workers, setWorkers] = useState<any>([]);

    const getWorkers = async () => {
        try {
            const response = await getWorkersFromJob(state.user?.token, id);
            if (response && Array.isArray(response)) {
                setWorkers(response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getWorkers()
    }, [])

    return (
        <table className='table'>
            <thead>
                <th>ROLE</th>
                <th>NAME</th>
                <th>PTS</th>
                <th>PHONE NUMBER</th>
            </thead>
            <tbody>
                {workers.map((worker: any)=> 
                    <tr>
                        <td>{worker.workRole}</td>
                        <td>{worker.fullName}</td>
                        <td>{worker.pts}</td>
                        <td>{worker.phoneNumber}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default WorkersTable
