import { Box, Button, Tab, Tabs, TextField } from "@mui/material";
import { ColorPicker } from "antd";
import React, { useState } from "react";
import  Modal from "react-modal";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Workers from "./Workers/Workers";
import { Modal as ModalAntd } from "antd";
import './GeofenceModal.css';

const GeofenceModal = ({
    open,
    setModalOpen,
    geofence,
    geofenceName,
    setGeofenceName,
    geofenceColor,
    setGeofenceColor,
    onSave,
    onDelete,
}: {
    open: boolean;
    geofenceName: string;
    geofence: any;
    setModalOpen: any;
    setGeofenceName: (name: string) => void;
    geofenceColor: string;
    setGeofenceColor: (color: string) => void;
    onSave: () => void;
    onDelete: () => void;
}) => {
    const [value, setValue] = useState(0);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    function CustomTabPanel(props: any) {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </div>
        );
    }
      
    CustomTabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
    
    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const handleChange = (event: any, newValue: any) => {
      setValue(newValue);
    };

    const closeEditModal = () => {
        setModalOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => {setModalOpen(false)}}
            className='geofence-modal'
            style={{
              overlay: { zIndex: 2 }
            }}
        >
            <div className='close-modal' onClick={closeEditModal}>
                <FontAwesomeIcon icon={faX} size={'lg'} />
            </div>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} width={'97%'}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Workers" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                {value === 0 && (
                    <div className="geofence-form">
                        <h2 className="geofence-h2">Geofence Details</h2>
                        <TextField
                            label="Geofence Name"
                            value={geofenceName}
                            onChange={(e: any) => { setGeofenceName(e.target.value)}}
                            fullWidth
                        />
                        <ColorPicker
                            style={{ fontSize: 20, margin: 20, zIndex: 10}}
                            showText
                            value={geofenceColor}
                            onChange={(color: any) => setGeofenceColor(color.toHexString())}
                        />
                        <div className="geofence-button">
                            <Button onClick={onSave} variant="contained" color="primary" style={{ marginTop: '10px', boxShadow: 'none' }}>
                                Save Geofence
                            </Button>
                            <Button onClick={() => setDeleteModalIsOpen(true)} variant="contained" style={{ marginTop: '10px', boxShadow: 'none', backgroundColor: 'red' }}>
                                Delete
                            </Button>
                        </div>
                    </div>
                )}
                {value === 1 && (
                    <Workers geofence={geofence} />
                )}
            </Box>
            <ModalAntd
                title={`Are you sure you want to delete?`}
                centered
                open={deleteModalIsOpen}
                okButtonProps={{ danger: true }}
                cancelText={"NO"}
                okText={"YES"}
                onOk={onDelete}
                onCancel={() => setDeleteModalIsOpen(false)}
            />
        </Modal>
    )
};

export default GeofenceModal;
